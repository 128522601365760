<template>
  <div id="login">
    <div class="form-header">
      <h3>Login Panou Administrare</h3>
      <p class="text-muted">Introduceți numele de utilizator și parola pentru a vă loga în panoul de administrare:</p>
    </div>

    <b-form class="form" novalidate v-on:submit.prevent="submit()">
      <b-form-group>
        <b-form-input
          name="username"
          type="text"
          class="form-control-solid"
          placeholder="Nume utilizator"
          v-model="username"
          v-bind:trim="true"
          v-bind:state="state.username"
        />

        <b-form-invalid-feedback id="username-feedback">
          <span v-if="!$v.username.required">Numele de utilizator este obligatoriu.</span>
          <span v-if="!$v.username.username">Numele de utilizator conține caractere nepermise.</span>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <b-form-input
          name="password"
          type="password"
          class="form-control-solid"
          placeholder="Parolă"
          v-model="password"
          v-bind:state="state.password"
        />

        <b-form-invalid-feedback id="password-feedback">
          <span v-if="!$v.password.required">Parola este obligatorie.</span>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <b-form-invalid-feedback v-if="invalidCredentials" id="invalid-credentials-feedback">
          Ați greșit numele de utilizator sau parola.
        </b-form-invalid-feedback>
      </b-form-group>

      <!--
      <b-form-group>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <div class="checkbox-inline">
            <label class="checkbox text-muted">
              <input type="checkbox" name="remember" v-model="remember" />
              <span></span> Pastrează-mă logat
            </label>
          </div>

          <a href="" class="text-muted text-hover-primary" @click.prevent="showForgot()">Am uitat parola</a>
        </div>
      </b-form-group>
      -->

      <button type="submit" class="btn btn-primary">Login</button>
    </b-form>
  </div>
</template>

<script>
import jQuery from "jquery";

import { validationMixin } from "vuelidate";
import { helpers, required } from "vuelidate/lib/validators";

import { isNullOrEmpty } from "@/core/utils";

const username = helpers.regex("text", /^[a-zA-Z_.-]+$/);

function mapValidationState(state) {
  if (state.$dirty) {
    if (state.$error) {
      return false;
    }

    if (!isNullOrEmpty(state.$model)) {
      return true;
    }
  }

  return null;
}

export default {
  mixins: [validationMixin],

  data() {
    return {
      username: null,
      password: null,
      remember: true,

      invalidCredentials: false,
    };
  },

  validations: {
    username: {
      username,
      required,
    },

    password: {
      required,
    },
  },

  computed: {
    state() {
      return {
        username: mapValidationState(this.$v.username),
        password: mapValidationState(this.$v.password),
      };
    },
  },

  watch: {
    username() {
      this.invalidCredentials = false;
    },

    password() {
      this.invalidCredentials = false;
    },
  },

  methods: {
    showForgot() {
      this.$emit("show-forgot");
    },

    async submit() {
      this.$v.$touch();
      this.invalidCredentials = false;

      if (this.$v.$anyError) return;

      jQuery("#login button").addClass("spinner spinner-light spinner-right");

      try {
        const request = {
          username: this.username,
          password: this.password,
          remember: this.remember,
        };

        await this.$store.dispatch("login", request);

        this.$router.push({ name: "dashboard" });
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.invalidCredentials = true;
        } else {
          throw e;
        }
      } finally {
        jQuery("#login button").removeClass("spinner spinner-light spinner-right");
      }
    },
  },
};
</script>

<style lang="scss">
#login {
  @import "@/assets/sass/pages/auth/common.scss";

  button {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  #invalid-credentials-feedback {
    display: block;
  }
}
</style>
